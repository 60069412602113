import React, { useState } from 'react';
import './register.scss';
import axios from 'axios';


function Register() {

  const [inputs, setInputs] = useState('');
  const [err, setErr] = useState(null);
  const [ifClicked, setIfClicked] = useState(false);

  const handleSubmit = async (e) => {
    var inputsArr = inputs.replace(/\n+/g, '\n').split('\n').map(item => item.trim().split(', '));
    if (inputsArr.some(item => (item.length > 5))) {
      setErr('입력이 올바르지 않습니다.')
    } 
    else {
      let errFlag = false;
      let setIds = new Set();
      for (let i=0; i<inputsArr.length; i++) {
        if (setIds.has(inputsArr[i][0])) {
          errFlag = true;
          setErr('중복된 아이디가 있습니다.');
          break;
        } else {
          setIds.add(inputsArr[i][0]);
        }

        if (inputsArr[i][1].length < 8) {
          errFlag = true;
          setErr('비밀번호는 8자리 이상이어야 합니다.');
          break;
        }
        

        if (!['농가','생산자단체','중도매인','가공업자','유통업자','소매업자','요식업자','소비자','기타'].some(candi => candi === inputsArr[i][2])) {
          errFlag = true;
          console.log(inputsArr)
          setErr("타입은 '농가','생산자단체','중도매인','가공업자','유통업자','소매업자','요식업자','소비자','기타' 중 하나여야 합니다.");
          break;
        }
      }
      if (!errFlag) {
        setIfClicked(true);

        const promises = [];
        inputsArr.forEach(item => { 
          promises.push(axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/register`,
          {
            'username': item[0],
            'password': item[1],
            'type': item[2],
            'name': item[3],
            'id': item.length==4 ? null : item[4],
            'acctype': 'normal'
          },
          {
            withCredentials: true,
          }))
        });
        
        
        await Promise.all(promises)
        .then(res => {
          alert(`${promises?.length} 개의 유저가 성공적으로 등록되었습니다!`);
          setInputs('');
          setErr(null);
          setIfClicked(false);
        })
        .catch(err => {
          console.log(err.response.data);
          setErr(err.response.data);
        })
      }
    }
  }

  return (
    <div className='register'>
      <div className="container">
        <h1 className="title">유저등록</h1>
        <div className="desc">유저를 일괄적으로 등록하기 위한 화면입니다.</div>
        <div className="content">
          <textarea 
            name="info" cols="30" rows="5"
            placeholder={`(입력예시)\n유저아이디, 비밀번호, 타입, 이름\n유저아이디, 비밀번호, 타입, 이름\n...`}
            onChange={(e)=>{setInputs(e.target.value); setErr(null)}}
            value={inputs}
          />
          {err && <div className="error">{err}</div>}
          <div className="buttons">
            <span className={`btnSubmit ${ifClicked? "clicked" : ""}`} onClick={ifClicked ? null : handleSubmit}>등록하기</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register