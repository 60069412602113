import React, { useContext } from 'react';
import './navbar.scss';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/authContext';

function Navbar() {

  const { adminLogout, currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = async (e) => {
    e.preventDefault();
    await adminLogout()
    .then(res => {
      console.log('로그아웃되었습니다.')
    })
  }

  return (
    <div className='navbar'>
        <div className="title" onClick={()=>navigate('/')}>
          <h1>파머스커넥트</h1>
          <span>ADMIN</span>
        </div>
        <div></div>
        <span className='btnSubmit btnLogout' onClick={handleLogout}>로그아웃</span>
    </div>
  )
}

export default Navbar