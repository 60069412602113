import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './login.scss';
import { AuthContext } from '../../context/authContext';


function Login() {

  const [inputs, setInputs] = useState({
    'username': '',
    'password': ''
  })
  const { adminLogin, adminLogout, adminRegister, currentUser } = useContext(AuthContext);
  const [err, setErr] = useState(null);

  useEffect(() => {
    if (currentUser) {
      navigate('/');
    }
  }, [currentUser]);

  const navigate = useNavigate();


  const handleChange = (e) => {
    setErr(null);
    setInputs(prev => ({ ...prev, [e.target.name]: e.target.value }));
  }
  const handleLogin = async (e) => {
    e.preventDefault();
    await adminLogin(inputs)
    .then(res => {
        console.log('로그인되었습니다!');
        setErr(null);
        navigate('/');
    })
    .catch(err => {
        setErr(err.response.data);
    })
  }
  const handleRegister = async (e) => {
    e.preventDefault();

    if (inputs.username === '' || inputs.password === '') {
        setErr('입력되지 않은 정보가 있습니다.')
    }
    else if (inputs.password.length < 8) {
        setErr('비밀번호를 8자 이상으로 설정해주세요.')
    }
    else {
        await adminRegister(inputs)
        .then(res => {
            console.log('등록되었습니다!');
            setInputs({
                'username': '',
                'password': '',
            })
            setErr(null);
        })
        .catch(err => {
            setErr(err.response.data);
        })
    }

  }
  

  return (
    <div className='login'>
        <div className="container">
            <span className='title'>파머스커넥트</span>
            <span className='title2'>ADMIN</span>
            <input 
                type="text" 
                placeholder="아이디"
                name='username'
                value={inputs.username}
                onChange={handleChange}
            />
            <input 
                type="password"
                placeholder='비밀번호'
                name='password'
                value={inputs.password}
                onChange={handleChange}
            />
            {err && <span className='error'>{err}</span>}
            <span className='btnSubmit btnLogin' onClick={handleLogin}>로그인하기</span>
            {/* <span className='btnSubmit btnRegister' onClick={handleRegister}>등록하기</span> */}
        </div>
    </div>
  )
}

export default Login