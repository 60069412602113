import React from 'react'
import './home.scss'

function Home() {
  return (
    <div className='home'>
      <div className="container">
        좌측에서 이용하실 기능을 선택해주세요.
      </div>
    </div>
  )
}

export default Home