import axios from 'axios';
import { createContext, useEffect, useState } from 'react';


export const AuthContext = createContext();

export const AuthContextProvider = ({children}) => {

    const [currentUser, setCurrentUser] = useState(
        JSON.parse(localStorage.getItem('user')) || null
    );

    const adminLogin = async (inputs) => {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/adminLogin`, inputs, {
            withCredentials: true,
        });

        await setCurrentUser(res.data);
    }

    const adminLogout = async () => {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/adminLogout`, {}, {
            withCredentials: true,
        });

        await setCurrentUser(null);
    }

    const adminRegister = async (inputs) => {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/adminRegister`, inputs, {
            withCredentials: true,
        });
    }

    useEffect(()=>{
        localStorage.setItem('user', JSON.stringify(currentUser));
    }, [currentUser]);

    return (
        <AuthContext.Provider value={{ currentUser, setCurrentUser, adminLogin, adminLogout, adminRegister }} >
            {children}
        </AuthContext.Provider>
    );
}

