import { useContext } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate
} from 'react-router-dom';
import './app.scss';
import { AuthContext } from './context/authContext';
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import Register from './pages/register/Register';
import Leftbar from './components/leftbar/Leftbar';
import Navbar from './components/navbar/Navbar';


function App() {

  const { currentUser, setCurrentUser } = useContext(AuthContext);

  const ProtectedRoute = ({children}) => {
    if (!currentUser) {
      return <Navigate to='/login' />;
    }

    return children;
  }

  const Layout = () => {
    return (
      <div>
        <Navbar />
        <div style={{ display: 'flex' }}>
          <Leftbar />
          <div style={{ flex: 4 }}>
            <Outlet />
          </div>
        </div>
      </div>
    )
  };


  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: '/',
          element: <Home />
        },
        {
          path: '/user-register',
          element: <Register />
        },
      ] 
    },
    {
      path: '/login',
      element: <Login />
    }
  ])

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
