import React from 'react';
import { useNavigate } from 'react-router-dom';
import './leftbar.scss';


function Leftbar() {

  const navigate = useNavigate();

  return (
    <div className='leftbar'>
      <div className="container">
        <div className="item" onClick={()=>navigate('/user-register')}>
          유저등록
        </div>
      </div>
    </div>
  )
}


export default Leftbar